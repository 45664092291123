import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function Footer() {  
    return (
        <Typography variant="body2" color="text.secondary" align="center" sx={{ pb: 6 }}>
            {'Copyright '}
            <Link color="inherit"  target="_blank" rel="noopener noreferrer" href="https://www.dopus.io">
                dOpus
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}
