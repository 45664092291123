import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Button, Card, CardActions, CardContent, CssBaseline, Grid, Box, Typography, Container, Dialog, DialogTitle } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, Share as ShareIcon} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Autocomplete, TextField } from '@mui/material';
import jobsData from './data/jobsData';
import ApplicationForm from "./ApplicationForm";
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';

const defaultTheme = createTheme();

export default function Careers() {
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]); 
    const [job, setJob] = useState(null);
    const [showJobs, setShowJobs] = useState(true);
    const [showSearch, setShowSearch] = useState(true);
    const [showJob, setShowJob] = useState(false);
    const [content, setContent] = useState(null);
    const [openApplyDialog, setOpenApplyDialog] = useState(false); // State for dialog visibility

    const [searchDepartments, setSearchDepartments] = useState([]);
    const [departmentSearchValue, setDepartmentSearchValue] = useState(null);
    const [searchCities, setSearchCities] = useState([]);
    const [citySearchValue, setCitySearchValue] = useState(null);

    useEffect(() => {
        const data = jobsData;
        const lists = data.jobs;

        const jobLocations = [...new Set(lists.map(list => list.location.name))].sort();
        const jobDepartments = [...new Set(lists.map(list => list.departments[0].name))].sort();

        setJobs(lists);
        setSearchCities(jobLocations);
        setSearchDepartments(jobDepartments);
        setFilteredJobs(lists); // Initially show all jobs

        // Get the job ID from the URL
        const jobIdFromUrl = window.location.pathname.split('/').pop(); // Assuming the URL ends with the job ID
        if (jobIdFromUrl) {
            const selectedJob = lists.find(job => job.id === parseInt(jobIdFromUrl, 10));
            if (selectedJob) {
                showJobDetails(selectedJob);
            }
        }
    }, []);

    // Combined filtering logic based on department and city selections
    const filterJobs = (department, city) => {
        const results = jobs.filter((job) => {
            const matchesDepartment = department ? job.departments[0].name === department : true;
            const matchesCity = city ? job.location.name === city : true;
            return matchesDepartment && matchesCity;
        });
        setFilteredJobs(results);
    };

    const handleSelectDepartment = (event, value) => {
        setDepartmentSearchValue(value);
        filterJobs(value, citySearchValue);

         // Log the search event
        logEvent(analytics, 'search', {
            search_type: 'department',
            search_value: value
        });
    };

    const handleSelectCity = (event, value) => {
        setCitySearchValue(value);
        filterJobs(departmentSearchValue, value);

        // Log the search event
        logEvent(analytics, 'search', {
            search_type: 'city',
            search_value: value
        });
    };

    const handleClickToJobDetail = (event, job) => {
        showJobDetails(job);

        // Log the event to Firebase Analytics
        logEvent(analytics, 'job_view', {
            job_title: job.title,
            job_location: job.location.name
        });


        // Update the URL with the job ID
        window.history.pushState(null, '', `/vacancy/${job.id}`);
    };

    const showJobDetails = (job) => {
        const parseJobDetail = parse(job.content);
        setShowJobs(false);
        setShowJob(true);
        setJob(job);
        setContent(parseJobDetail);
        setShowSearch(false);
    };

    const handleClickToAllJobs = () => {
        setShowJobs(true);
        setShowJob(false);
        setDepartmentSearchValue(null);
        setCitySearchValue(null);
        setFilteredJobs(jobs);
        setShowSearch(true);

        // Clear the URL
        window.history.pushState(null, '', '/vacancy');
    };


    const handleClickShareJob = () => {
        if (job) {
            const jobUrl = `${window.location.origin}/vacancy/${job.id}`;
            const jobTitle = job.title;
            
              // Log the share event
            logEvent(analytics, 'share_job', {
                job_title: jobTitle,
                job_url: jobUrl
            });

            // Check if the Web Share API is supported
            if (navigator.share) {
                navigator.share({
                    title: `Check out this job at dOpus: ${jobTitle}`,
                    text: `I found this interesting job opportunity at dOpus: ${jobTitle}`,
                    url: jobUrl
                }).then(() => {
                    console.log('Job shared successfully!');
                }).catch((err) => {
                    console.error('Error sharing job:', err);
                });
            } else {
                // Fallback: copy link to clipboard if Web Share API is not supported
                navigator.clipboard.writeText(jobUrl).then(() => {
                    alert('Job link copied to clipboard!');
                }, (err) => {
                    alert('Failed to copy the job link');
                });
            }
        }
    };

        // Function to open the apply dialog
        const handleClickOpenApplyDialog = () => {
            setOpenApplyDialog(true);
        };
    
        // Function to close the apply dialog
        const handleCloseApplyDialog = () => {
            setOpenApplyDialog(false);
        };

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            {/* Searchbar for department section */}
            {showSearch && 
                <Container sx={{ justifyContent: 'center', display: 'flex', padding: '0 20px 20px' }}>
                    <Autocomplete
                        options={searchDepartments}
                        value={departmentSearchValue}
                        onChange={handleSelectDepartment}
                        sx={{ width: 500 }}
                        renderInput={(params) => <TextField {...params} label="Departments" />}
                    />
                </Container>
            }

            {/* Searchbar for cities section */}
            {showSearch && 
                <Container sx={{ justifyContent: 'center', display: 'flex', padding: '0 20px 50px' }}>
                    <Autocomplete
                        options={searchCities}
                        value={citySearchValue}
                        onChange={handleSelectCity}
                        sx={{ width: 500 }}
                        renderInput={(params) => <TextField {...params} label="Job Locations" />}
                    />
                </Container>
            }   

            {showJobs && 
                <Container sx={{ py: 2 }} maxWidth="md">
                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                        Open Positions
                    </Typography>
                    <Grid container spacing={4}>
                        {filteredJobs && filteredJobs.length > 0 && filteredJobs.map((job) => (
                            <Grid item key={job.id} xs={12} sm={6} md={4}>
                                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography component="h6">
                                            {job.location.name}
                                        </Typography>
                                        <Typography gutterBottom variant="p" component="h4">
                                            {job.title}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button onClick={event => handleClickToJobDetail(event, job)} size="small">Job Details</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            }

            {/* Job detail section */}
            {showJob && 
                <Container>
                    <Card sx={{ height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                        <CardContent>
                            <Box align="left" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                <Button onClick={handleClickToAllJobs} size="small">
                                    <ChevronLeftIcon />
                                    Back To Jobs
                                </Button>
                                <Button onClick={handleClickShareJob} size="small">
                                    <ShareIcon />
                                    Share This Job
                                </Button>
                            </Box>
                            <Typography component="h1" id="job-detail" sx={{ fontWeight: 'bold', fontSize: '1.3rem', paddingTop: '20px' }}>
                                {job.title}
                            </Typography>
                            <Typography id="content" component="div">
                                {content}
                            </Typography>


                             {/* Button to apply for the job */}
                             <Box sx={{ marginTop: 2 }}>
                                <Button variant="contained" color="primary" onClick={handleClickOpenApplyDialog}>
                                    Apply for this Job
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                        {/* Social Media Icons Section */}
                        <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
                            <Button size="small" href="https://www.dopus.io" target="_blank">
                                <LanguageIcon />
                            </Button>
                            <Button size="small" href="https://x.com/dopus0x" target="_blank">
                                <TwitterIcon />
                            </Button>
                            <Button size="small" href="https://t.me/+3gPgxP6zI8NlZWJk" target="_blank">
                                <TelegramIcon />
                            </Button>
                        </Box>
                </Container>
            }
            {/* Dialog for applying to the job */}
            <Dialog open={openApplyDialog} onClose={handleCloseApplyDialog}>
                <DialogTitle>Apply for {job?.title}</DialogTitle>
                <ApplicationForm setOpenApplyDialog={setOpenApplyDialog} job={job} />
                </Dialog>                
        </ThemeProvider>
    );
}
