import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link'; // Import Link from Material-UI
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import { Button } from '@mui/material';

export default function Header() {  
    return (
        <>
            <AppBar position="relative">
                <Toolbar>
                <Typography variant="h4" color="inherit" noWrap>
                        {/* Wrap "dOpus" with a clickable link */}
                        <Link href="https://www.dopus.io" color="inherit" underline="none" target="_blank" rel="noopener noreferrer">
                            dOpus
                        </Link> Careers
                    </Typography>
            
                </Toolbar>
            </AppBar>
            <Container sx={{ py: 2, display: 'flex', justifyContent: 'space-between'}} maxWidth="md">
                <Box sx={{ bgcolor: 'background.paper', pt: 6 }} >
                    <Container maxWidth="md">
                        <Typography component="h2" variant="h4" align="center" color="text.primary" gutterBottom >
                        Together, we decentralize advertising for everyday spaces around the world.
                        </Typography>
                    </Container>
                </Box>  
            </Container>
            <Container sx={{ py: 2, display: 'flex', justifyContent: 'center' }} maxWidth="md">
                {/* Social Media Icons Section */}
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , pb: 3 }}>
                    <Button size="small" href="https://www.dopus.io" target="_blank">
                        <LanguageIcon />
                    </Button>
                    <Button size="small" href="https://x.com/dopus0x" target="_blank">
                        <TwitterIcon />
                    </Button>
                    <Button size="small" href="https://t.me/+3gPgxP6zI8NlZWJk" target="_blank">
                        <TelegramIcon />
                    </Button>
                </Box>
            </Container>

        </>
    )
}