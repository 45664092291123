// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCT4e8ZNx_7wbZdaDTKZVm3tjQXBccscnA",
    authDomain: "dopus-ico-careers.firebaseapp.com",
    projectId: "dopus-ico-careers",
    storageBucket: "dopus-ico-careers.appspot.com",
    messagingSenderId: "979182044438",
    appId: "1:979182044438:web:66fde146fb71c298a7fc37",
    measurementId: "G-7JC5Y7LVHZ"
};
  
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app); // Firestore instance
export const storage = getStorage(app); // Firebase storage instance
export const analytics = getAnalytics(app); // Analytics instance