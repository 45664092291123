import React, { useState, useEffect } from 'react';
import { TextField, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { db, storage, analytics } from './firebaseConfig';
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { logEvent } from 'firebase/analytics';

export default function ApplicationForm({ setOpenApplyDialog, job }) {
    // Existing state variables
    const [fullName, setFullName] = useState('');
    const [county, setCounty] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [social, setSocial] = useState('');
    const [salaryExpectations, setSalaryExpectations] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileError, setFileError] = useState('');
    const [formError, setFormError] = useState('');
    const [uploading, setUploading] = useState(false);
    const [metadata, setMetadata] = useState({});

    // New success state variable
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const detectMetadata = () => {
            // Initialize metadata object
            const newMetadata = {};

            // Check for userAgentData support (User-Agent Client Hints API)
            if (navigator.userAgentData) {
                navigator.userAgentData.getHighEntropyValues(['platform'])
                    .then(ua => {
                        newMetadata.platform = ua.platform || 'Unknown';
                        newMetadata.userAgent = ua.uaFullVersion || 'Unknown';
                        newMetadata.language = navigator.language || 'Unknown';

                        setMetadata(newMetadata);
                    })
                    .catch(() => {
                        // Fallback if getHighEntropyValues fails
                        newMetadata.userAgent = navigator.userAgent || 'Unknown';
                        newMetadata.language = navigator.language || 'Unknown';

                        setMetadata(newMetadata);
                    });
            } else {
                // Fallback for browsers that do not support userAgentData
                newMetadata.userAgent = navigator.userAgent || 'Unknown';
                newMetadata.language = navigator.language || 'Unknown';

                setMetadata(newMetadata);
            }
        };

        detectMetadata();
    }, []);

    // Email validation function
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Validate file format and size
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];

        if (file && allowedTypes.includes(file.type) && file.size <= 3 * 1024 * 1024) {
            setSelectedFile(file);
            setFileError(''); // Clear any previous error
        } else {
            setFileError('File must be a PDF, DOC, or DOCX and less than 3MB.');
        }
    };

    // Validate required fields
    const validateForm = () => {
        if (!fullName || !county || !email || !selectedFile) {
            setFormError('Please fill in all the required fields and upload your CV.');
            return false;
        }

        // Email validation
        if (!isValidEmail(email)) {
            setFormError('Please enter a valid email address.');
            return false;
        }

        setFormError('');
        return true;
    };

    // Submit the form
    const handleSubmit = async () => {
        if (!validateForm()) return; // Stop form submission if validation fails

        setUploading(true);

        try {
            // Step 1: Append the current timestamp to the file name
            const timestamp = new Date().getTime();
            const fileExtension = selectedFile.name.split('.').pop();
            const fileNameWithoutExtension = selectedFile.name.split('.').slice(0, -1).join('.');
            const newFileName = `${fileNameWithoutExtension}_${timestamp}.${fileExtension}`;

            // Step 2: Upload resume to Firebase Storage
            const storageRef = ref(storage, `resumes/${newFileName}`);
            const uploadTask = uploadBytesResumable(storageRef, selectedFile);

            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.error('File upload error:', error);
                    setUploading(false);
                },
                async () => {
                    const fileURL = await getDownloadURL(uploadTask.snapshot.ref);

                    // Step 3: Add application data to Firestore
                    await addDoc(collection(db, "applications"), {
                        fullName,
                        county,
                        email,
                        salaryExpectations,
                        website,
                        social,
                        additionalInfo,
                        resumeURL: fileURL,
                        jobTitle: job.title,
                        createdAt: new Date(),
                        ...metadata,
                    });

                    // Log the event to Firebase Analytics
                    logEvent(analytics, 'submit_application', {
                        applicant_name: fullName,
                        applicant_email: email,
                        job_title: job.title
                    });

                    // Set success to true to display the success message
                    setSuccess(true);
                    setUploading(false);
                }
            );
        } catch (error) {
            console.error('Error submitting application:', error);
            setUploading(false);
        }
    };

    return (
        <DialogContent>
            {success ? (
                // Success message
                <div>
                    <Typography variant="h6" gutterBottom>
                        Thank you for applying!
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Dear {fullName},
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Thank you for applying for the position of {job?.title} at dOpus. Your application has been received successfully. Our team will review your application, and if your qualifications match our requirements, we will contact you for the next steps.
                    </Typography>
                    <Typography variant="body1">
                        We appreciate your interest in joining our team.
                    </Typography>
                    <DialogActions>
                        <Button onClick={() => setOpenApplyDialog(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </div>
            ) : (
                // Application form
                <>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        label="Full Name"
                        fullWidth
                        variant="standard"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                    <TextField
                        required
                        margin="dense"
                        label="County"
                        fullWidth
                        variant="standard"
                        value={county}
                        onChange={(e) => setCounty(e.target.value)}
                    />
                    <TextField
                        required
                        margin="dense"
                        label="Email"
                        fullWidth
                        variant="standard"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!isValidEmail(email) && email !== ''}
                        helperText={!isValidEmail(email) && email !== '' ? 'Please enter a valid email address' : ''}
                    />
                    <TextField
                        margin="dense"
                        label="Salary Expectations (Yearly in USD)"
                        fullWidth
                        variant="standard"
                        value={salaryExpectations}
                        onChange={(e) => setSalaryExpectations(e.target.value)}
                        type="number" // Make sure it's a number input
                    />
                    <TextField
                        margin="dense"
                        label="Website"
                        fullWidth
                        variant="standard"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Social"
                        fullWidth
                        variant="standard"
                        value={social}
                        onChange={(e) => setSocial(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Additional Info"
                        fullWidth
                        variant="standard"
                        multiline
                        rows={4}
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                    />

                    {/* Custom file upload for CV */}
                    <label htmlFor="cv-upload" style={{ display: 'block', marginTop: '16px' }}>
                        <Button variant="outlined" component="span" color="primary">
                            Attach CV (PDF, DOC, DOCX)
                        </Button>
                    </label>
                    <input
                        id="cv-upload"
                        accept=".pdf, .doc, .docx"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    {fileError && <p style={{ color: 'red' }}>{fileError}</p>}

                    {selectedFile && (
                        <p style={{ marginTop: '8px' }}>
                            Selected file: {selectedFile.name} ({(selectedFile.size / 1024 / 1024).toFixed(2)} MB)
                        </p>
                    )}

                    {formError && <p style={{ color: 'red' }}>{formError}</p>}

                    <DialogActions>
                        <Button onClick={() => setOpenApplyDialog(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary" disabled={uploading}>
                            {uploading ? 'Submitting...' : 'Submit'}
                        </Button>
                    </DialogActions>
                </>
            )}
        </DialogContent>
    );
}
