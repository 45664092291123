// jobsData.js

const jobsData = {
    jobs: [
      {       
        location: {
          name: "Remote (Worldwide)"
        },
        departments: [
          {
            id: 1001,
            name: "Social Media & Web3",
            child_ids: [],
            parent_id: null
          }
        ],
        id: 8075607,
        updated_at: "2024-09-17T08:22:34-04:00",
        title: "Web3 Community Lead",
        content: `
          <p style="text-align: left;">dOpus is revolutionizing decentralized advertising by allowing property owners to monetize their spaces—be it a car, home, or any owned property—by connecting with brands through our marketplace. As a Web3 Community Lead, you’ll play a critical role in engaging with our growing community, helping them understand and embrace this innovative approach to advertising. We’re looking for a passionate leader who thrives in building thriving communities and educating users about the power of decentralization.</p>

          <p style="text-align: left;"><strong>Responsibilities:</strong></p>
          <ul style="text-align: left;">
            <li>Lead and manage dOpus’ global community across various platforms like X (formerly Twitter), Discord, Telegram, and other social channels.</li>
            <li>Develop and implement effective community engagement strategies to increase awareness and adoption of dOpus.</li>
            <li>Oversee daily community operations, ensuring active and informative engagement with our users.</li>
            <li>Identify, recruit, and onboard community managers to maintain an active presence globally.</li>
            <li>Engage with the community regularly to foster a positive, knowledgeable, and collaborative environment.</li>
            <li>Organize and execute online events, AMAs, and interactive activities to drive growth, participation, and education.</li>
            <li>Stay informed on the latest Web3 trends, ensuring the community is kept up-to-date with relevant insights.</li>
            <li>Serve as a role model for the community team, demonstrating proactive, transparent, and enthusiastic engagement.</li>
            <li>Identify key community contributors and empower them to take on larger roles within the dOpus ecosystem.</li>
          </ul>

          <p style="text-align: left;"><strong>Qualifications:</strong></p>
          <ul style="text-align: left;">
            <li>Proven experience in managing and growing online communities, preferably in Web3, decentralized platforms, or similar spaces.</li>
            <li>Strong understanding of Web3 concepts, blockchain, and decentralized marketplaces.</li>
            <li>Experience using community platforms such as X (formerly Twitter), Discord, Telegram, and others.</li>
            <li>Familiarity with decentralized engagement tools like airdrop campaigns, community games, and bots.</li>
            <li>Excellent communication, collaboration, and organizational skills.</li>
            <li>Ability to work effectively in a fast-paced, fully remote environment.</li>
            <li>Passion for Web3 and a commitment to building and nurturing an engaged, informed community.</li>
          </ul>

          <p style="text-align: left;"><strong>Bonus Points:</strong></p>
          <ul style="text-align: left;">
            <li>An established network of community managers or influencers in the Web3 space.</li>
            <li>Experience with event planning, community-building campaigns, and managing fast-growing communities.</li>
            <li>Proven leadership skills in managing decentralized or blockchain-based communities.</li>
          </ul>

          <p style="text-align: left;"><strong>What We Offer:</strong></p>
          <ul style="text-align: left;">
            <li>Competitive salary with remote work.</li>
            <li>Opportunity to work closely with a passionate Web3 team that is reshaping the advertising landscape.</li>
            <li>Participation in employee bonus and Airdrop programs.</li>
            <li>Excellent career growth opportunities for high performers.</li>
          </ul>

          <p style="text-align: left;">If you are passionate about decentralization and have the experience to lead a dynamic Web3 community, we encourage you to apply and become part of the dOpus journey!</p>

       <p style="text-align: left;"><em>dOpus is an equal opportunity employer committed to fostering an inclusive, innovative environment with the best employees. Therefore, we provide employment opportunities without regard to age, race, color, ancestry, national origin, disability, gender, or any other protected status in accordance with applicable law.</em></p>
    `,     
      },
      {       
        location: {
          name: "Remote (CET and EET)"
        },
        departments: [
          {
            id: 1005,
            name: "Software Engineer",
            child_ids: [],
            parent_id: null
          }
        ],
        id: 2425336,
        updated_at: "2024-09-17T08:22:34-04:00",
        title: "Frontend Lead",
        content: `
          <p style="text-align: left;">dOpus is revolutionizing decentralized advertising by allowing property owners to monetize their spaces—be it a car, home, or any owned property—by connecting with brands through our marketplace. As a Frontend Lead, you’ll be a crucial part of our team, helping to craft a seamless and intuitive experience for users engaging with dOpus. We’re looking for a skilled frontend engineer with a passion for building beautiful, functional, and scalable web interfaces that push the boundaries of decentralized platforms.</p>

        <p style="text-align: left;"><strong>Responsibilities:</strong></p>
        <ul style="text-align: left;">
          <li>Develop new user-facing features that are not only functional but visually stunning and intuitive.</li>
          <li>Work closely with our design team from initial concepts to final UI components, ensuring an exceptional user experience.</li>
          <li>Collaborate with the product and backend teams to rapidly bring new features to life, while ensuring the stability and scalability of the frontend architecture.</li>
          <li>Continuously iterate on the UI/UX based on user feedback and analytics, ensuring a smooth experience across all devices and platforms.</li>
          <li>Implement frontend security best practices to safeguard user data and ensure the integrity of our platform.</li>
          <li>Take ownership of technology decisions that align with the long-term goals of dOpus, ensuring the frontend remains robust, scalable, and cutting-edge.</li>
          <li>Contribute to the team’s craftsmanship by sharing expertise and maintaining top-level quality across the board.</li>
        </ul>

        <p style="text-align: left;"><strong>Qualifications:</strong></p>
        <ul style="text-align: left;">
          <li>Proven experience as a frontend engineer, with a track record of building and shipping successful web interfaces.</li>
          <li>Strong knowledge of the React framework and modern frontend development practices.</li>
          <li>Proficiency with CSS libraries and creating responsive, cross-platform designs.</li>
          <li>Experience in building scalable frontend architecture with a focus on performance and security.</li>
          <li>Excellent troubleshooting and problem-solving skills with the ability to work autonomously in a fast-paced environment.</li>
          <li>Great communication skills, with the ability to collaborate with cross-functional teams and contribute to product discussions.</li>
          <li>Passion for continuous product iteration and the ability to see the bigger picture while prioritizing shipping efficiently.</li>
        </ul>

        <p style="text-align: left;"><strong>Bonus Points:</strong></p>
        <ul style="text-align: left;">
          <li>Experience working with decentralized platforms or Web3 technologies.</li>
          <li>Proven leadership experience in guiding and mentoring engineering teams.</li>
          <li>Familiarity with frontend testing frameworks and automated testing practices.</li>
        </ul>

        <p style="text-align: left;"><strong>What We Offer:</strong></p>
        <ul style="text-align: left;">
          <li>Competitive salary with remote work.</li>
          <li>Opportunity to work closely with a passionate Web3 team that is reshaping the advertising landscape.</li>
          <li>Participation in employee bonus and Airdrop programs.</li>
          <li>Excellent career growth opportunities for high performers.</li>
        </ul>

        <p style="text-align: left;">If you are passionate about crafting top-notch user experiences and want to be part of a forward-thinking decentralized platform, we encourage you to apply and join the dOpus journey!</p>

        <p style="text-align: left;"><em>dOpus is an equal opportunity employer committed to fostering an inclusive, innovative environment with the best employees. Therefore, we provide employm
      `,     
      },
      {       
        location: {
          name: "Remote (CET and EET)"
        },
        departments: [
          {
            id: 1005,
            name: "Software Engineer",
            child_ids: [],
            parent_id: null
          }
        ],
        id: 9128530,
        updated_at: "2024-09-17T08:22:34-04:00",
        title: "Lead Mobile Developer (Flutter)",
        content: `
          <p style="text-align: left;">dOpus is revolutionizing decentralized advertising by allowing property owners to monetize their spaces—be it a car, home, or any owned property—by connecting with brands through our marketplace. As a Lead Mobile Developer, you’ll be instrumental in developing and refining dOpus’ mobile application, built with Flutter, ensuring a smooth and responsive user experience across both iOS and Android platforms. We’re looking for a technical expert to lead the mobile development efforts, integrate cutting-edge features, and help shape the future of decentralized advertising in everyday spaces.</p>

<p style="text-align: left;"><strong>Responsibilities:</strong></p>
<ul style="text-align: left;">
  <li>Lead the development and enhancement of the dOpus mobile app using Flutter.</li>
  <li>Collaborate with the product team to prioritize and implement features based on user feedback and market demands.</li>
  <li>Integrate and optimize backend services, ensuring seamless performance and scalability.</li>
  <li>Work with state management solutions like Provider, Riverpod, or Bloc to create maintainable and scalable code.</li>
  <li>Test and debug API integrations using tools such as Postman.</li>
  <li>Implement image and file storage solutions using services like Wasabi.</li>
  <li>Utilize MVVM architecture to ensure the app is robust and easy to maintain.</li>
  <li>Incorporate real-time communications within the app using Socket.io.</li>
  <li>Manage backend services with databases like MongoDB and Firebase.</li>
  <li>Work closely with the design team to bring UI/UX designs to life and ensure a seamless mobile experience.</li>
  <li>Conduct code reviews and provide mentorship as the development team expands.</li>
  <li>Stay up-to-date with the latest mobile development trends and tools.</li>
  <li>Contribute to dOpus’ overall technical strategy and product vision.</li>
</ul>

<p style="text-align: left;"><strong>Qualifications:</strong></p>
<ul style="text-align: left;">
  <li>Proven experience with Flutter and Dart, with a focus on building mobile apps for both iOS and Android.</li>
  <li>Strong understanding of backend technologies such as Node.js, Express, and API integration.</li>
  <li>Experience with cloud platforms like Firebase and Azure.</li>
  <li>Knowledge of state management solutions in Flutter, such as Provider, Riverpod, or Bloc.</li>
  <li>Experience testing and debugging APIs using tools like Postman.</li>
  <li>Familiarity with MVVM architecture and real-time communication solutions like Socket.io.</li>
  <li>Proficiency in managing databases such as MongoDB and Firebase.</li>
  <li>Solid experience with Git and version control systems.</li>
  <li>Strong problem-solving skills, attention to detail, and communication abilities.</li>
  <li>An entrepreneurial mindset with the ability to take ownership of tasks and collaborate effectively.</li>
</ul>

<p style="text-align: left;"><strong>Bonus Points:</strong></p>
<ul style="text-align: left;">
  <li>Experience working with decentralized platforms or Web3 technologies.</li>
  <li>Leadership experience in mobile development teams.</li>
  <li>Familiarity with Flutter testing frameworks and automated testing practices.</li>
</ul>

<p style="text-align: left;"><strong>What We Offer:</strong></p>
<ul style="text-align: left;">
  <li>Competitive salary with remote work.</li>
  <li>Opportunity to work closely with a passionate Web3 team that is reshaping the advertising landscape.</li>
  <li>Participation in employee bonus and Airdrop programs.</li>
  <li>Excellent career growth opportunities for high performers.</li>
</ul>

<p style="text-align: left;">If you are passionate about mobile development and want to lead a cutting-edge project in the decentralized space, we encourage you to apply and become part of the dOpus journey!</p>

<p style="text-align: left;"><em>dOpus is an equal opportunity employer committed to fostering an inclusive, innovative environment with the best employees. Therefore, we provide employment opportunities without regard to age, race, color, ancestry, national origin, disability, gender, or any other protected status in accordance with applicable law.</em></p>
`,     
      },{       
        location: {
          name: "Remote (CET and EET)"
        },
        departments: [
          {
            id: 1005,
            name: "Software Engineer",
            child_ids: [],
            parent_id: null
          }
        ],
        id: 9285993,
        updated_at: "2024-09-17T08:22:34-04:00",
        title: "Senior Fullstack Engineer",
        content: `
          <p style="text-align: left;">dOpus is revolutionizing decentralized advertising by allowing property owners to monetize their spaces—be it a car, home, or any owned property—by connecting with brands through our marketplace. We are seeking a Senior Fullstack Engineer who will play a pivotal role in building and scaling the platform, ensuring a smooth and efficient user experience across all layers of the stack. If you’re a technical leader with experience in both frontend and backend technologies, we want you to join our growing team and help shape the future of decentralized advertising.</p>

<p style="text-align: left;"><strong>Responsibilities:</strong></p>
<ul style="text-align: left;">
  <li>Collaborate closely with co-founders and key product leaders to scope and architect new features in an Agile environment.</li>
  <li>Work alongside dOpus’ engineering team in a flat structure to develop and enhance the platform’s services.</li>
  <li>Lead the hands-on development of both frontend and backend services for the dOpus platform.</li>
  <li>Oversee feature development and ensure it follows the Software Development Life Cycle (SDLC).</li>
  <li>Implement various tools and infrastructure for development, testing, automation, and IT operations.</li>
  <li>Take ownership of internal tools and infrastructure necessary to support our growing platform.</li>
  <li>Define and set processes for development, testing, release, update, and support for DevOps operations.</li>
  <li>Manage incidents and perform root cause analysis to improve system reliability.</li>
  <li>Monitor and measure customer experience and platform KPIs to ensure optimal performance.</li>
  <li>Conduct vulnerability and risk assessments to maintain cybersecurity best practices.</li>
  <li>Bring drive, initiative, and leadership as we expand our engineering team.</li>
</ul>

<p style="text-align: left;"><strong>Qualifications:</strong></p>
<ul style="text-align: left;">
  <li>4+ years of experience in a fullstack engineering role, with hands-on expertise in both frontend and backend development.</li>
  <li>Strong experience with system integrations and API development.</li>
  <li>Solid experience in QA automation and best practices.</li>
  <li>Proficiency in Python, with experience using Flask for backend development.</li>
  <li>Experience with databases like Postgres, Redis, and managing background tasks with tools like Huey.</li>
  <li>Familiarity with AWS services (EC2, S3, CloudFormation, ECS) and cloud infrastructure management.</li>
  <li>Experience with DevOps principles, Linux-based infrastructure, and managing databases such as MySQL and MongoDB.</li>
  <li>Knowledge of CI/CD pipelines, especially using tools like Github Actions, is a plus.</li>
  <li>Experience working in Agile environments with cross-functional teams.</li>
</ul>

<p style="text-align: left;"><strong>Bonus Points:</strong></p>
<ul style="text-align: left;">
  <li>Experience with payment gateways such as Stripe, Google Pay for Passes, or Apple Wallet.</li>
  <li>Familiarity with Apple Push Notification Service (APNS) for mobile integrations.</li>
  <li>Experience with containerization and orchestration tools like Kubernetes and Ansible.</li>
</ul>

<p style="text-align: left;"><strong>What We Offer:</strong></p>
<ul style="text-align: left;">
  <li>Competitive salary with remote work.</li>
  <li>Opportunity to work closely with a passionate Web3 team that is reshaping the advertising landscape.</li>
  <li>Participation in employee bonus and Airdrop programs.</li>
  <li>Excellent career growth opportunities for high performers.</li>
</ul>

<p style="text-align: left;">If you are passionate about fullstack development and want to lead technical initiatives in a decentralized space, we encourage you to apply and become part of the dOpus journey!</p>

<p style="text-align: left;"><em>dOpus is an equal opportunity employer committed to fostering an inclusive, innovative environment with the best employees. Therefore, we provide employment opportunities without regard to age, race, color, ancestry, national origin, disability, gender, or any other protected status in accordance with applicable law.</em></p>
`,     
      },{       
        location: {
          name: "Remote (Worldwide)"
        },
        departments: [
          {
            id: 1001,
            name: "Social Media & Web3",
            child_ids: [],
            parent_id: null
          }
        ],
        id: 3268654,
        updated_at: "2024-09-17T08:22:34-04:00",
        title: "Web3 Community Manager",
        content: `
          <p style="text-align: left;">dOpus is revolutionizing decentralized advertising by allowing property owners to monetize their spaces—be it a car, home, or any owned property—by connecting with brands through our marketplace. As a Crypto Community Manager, you’ll play a key role in managing and growing our vibrant community across various platforms, helping dOpus maintain strong engagement with our users and ensuring the community stays informed and excited about our platform. We are looking for someone passionate about crypto and Web3, with experience in community management.</p>

<p style="text-align: left;"><strong>Key Responsibilities:</strong></p>
<ul style="text-align: left;">
  <li>Manage and grow our crypto community on platforms such as X (formerly Twitter), Telegram, and Discord, and other social channels.</li>
  <li>Develop and implement effective engagement strategies to foster a vibrant and supportive community.</li>
  <li>Build and nurture strong relationships with key community members and contributors.</li>
  <li>Create engaging, informative content that educates and excites the community about dOpus and decentralized advertising.</li>
</ul>

<p style="text-align: left;"><strong>Ideal Candidate:</strong></p>
<ul style="text-align: left;">
  <li>Deep understanding of the crypto and blockchain space, with the ability to engage meaningfully with the community.</li>
  <li>Excellent communication skills, both written and verbal.</li>
  <li>Experience managing and growing communities in the crypto space.</li>
</ul>

<p style="text-align: left;"><strong>What We Offer:</strong></p>
<ul style="text-align: left;">
  <li>Competitive salary and benefits package.</li>
  <li>Opportunity to work with a leading platform in decentralized advertising and the broader blockchain industry.</li>
  <li>Flexible working hours and remote work.</li>
  <li>A collaborative and innovative work environment that fosters growth and creativity.</li>
</ul>

<p style="text-align: left;">If you are passionate about crypto, community building, and want to be part of a forward-thinking decentralized platform, we encourage you to apply and become part of the dOpus journey!</p>

<p style="text-align: left;"><em>dOpus is an equal opportunity employer committed to fostering an inclusive, innovative environment with the best employees. Therefore, we provide employment opportunities without regard to age, race, color, ancestry, national origin, disability, gender, or any other protected status in accordance with applicable law.</em></p>
`,     
      },{       
        location: {
          name: "Remote (Worldwide)"
        },
        departments: [
          {
            id: 1001,
            name: "Social Media & Web3",
            child_ids: [],
            parent_id: null
          }
        ],
        id: 6777019,
        updated_at: "2024-09-17T08:22:34-04:00",
        title: "Ambassador",
        content: `
          <p style="text-align: left;">dOpus is revolutionizing decentralized advertising by allowing property owners to monetize their spaces—be it a car, home, or any owned property—by connecting with brands through our marketplace. As a dOpus Ambassador, you’ll play a key role in representing our mission and spreading awareness of decentralized advertising in your region. We are looking for passionate individuals who are eager to contribute to the growth of dOpus, engage with the community, and help create valuable content that excites and educates users about our platform.</p>

<p style="text-align: left;"><strong>Who You Are:</strong></p>
<ul style="text-align: left;">
  <li>Passionate about dOpus’ mission to revolutionize advertising through decentralization.</li>
  <li>Eager to get more involved in growing the dOpus community and help the network expand.</li>
  <li>Currently engaging and networking with others in the blockchain, crypto, and advertising spaces.</li>
  <li>Demonstrated contributions to Web3 or advertising ecosystems through your unique skill set (content creation, event hosting, etc.).</li>
</ul>

<p style="text-align: left;"><strong>What You’ll Do:</strong></p>
<ul style="text-align: left;">
  <li>Actively participate in dOpus community channels (X (formerly Twitter), Discord, Telegram) to engage with the audience.</li>
  <li>Create content such as blogs, guides, video series, or even coding and design contributions to promote decentralized advertising.</li>
  <li>Host virtual events and/or physical meetups on dOpus-related topics to educate and engage with the community.</li>
  <li>Represent your region in the dOpus community, sharing insights and helping local users understand the benefits of decentralized advertising.</li>
  <li>Receive support for initiatives that align with dOpus' vision to enable property owners to monetize their spaces securely and efficiently.</li>
  <li>Network and collaborate with other ambassadors to share best practices and support each other's initiatives.</li>
</ul>

<p style="text-align: left;"><strong>Ideal Candidate:</strong></p>
<ul style="text-align: left;">
  <li>Deep understanding of the crypto, Web3, and blockchain space, with a passion for decentralized technologies.</li>
  <li>Excellent communication skills, both written and verbal, with a knack for engaging and inspiring communities.</li>
  <li>Experience in creating and sharing content (written, visual, or technical) that resonates with diverse audiences.</li>
  <li>Already connected or engaging with communities in crypto, blockchain, and advertising-related fields.</li>
</ul>

<p style="text-align: left;"><strong>What We Offer:</strong></p>
<ul style="text-align: left;">
  <li>Opportunity to be a part of a rapidly growing decentralized platform.</li>
  <li>Access to exclusive dOpus content, events, and collaborations.</li>
  <li>Support for your initiatives that align with dOpus’ vision of decentralized advertising.</li>
  <li>Flexible working hours with the freedom to engage and contribute in ways that leverage your unique skill set.</li>
  <li>A collaborative, innovative, and passionate community that fosters growth and creativity.</li>
</ul>

<p style="text-align: left;">If you are passionate about decentralized advertising, community engagement, and want to represent dOpus in your region, we encourage you to apply and become part of the dOpus journey!</p>

<p style="text-align: left;"><em>dOpus is an equal opportunity organization committed to fostering an inclusive, innovative environment. We provide opportunities without regard to age, race, color, ancestry, national origin, disability, gender, or any other protected status in accordance with applicable law.</em></p>
`,     
      },{       
        location: {
          name: "Dubai, UAE"
        },
        departments: [
          {
            id: 1002,
            name: "Marketing",
            child_ids: [],
            parent_id: null
          }
        ],
        id: 6198983,
        updated_at: "2024-09-17T08:22:34-04:00",
        title: "Product Marketing Specialist",
        content: `
          <p style="text-align: left;">dOpus is revolutionizing decentralized advertising by allowing property owners to monetize their spaces—be it a car, home, or any owned property—by connecting with brands through our marketplace. We are seeking a Product Marketing Specialist to join our team and play a key role in positioning and promoting dOpus products to the market. This role requires someone with a strong understanding of the tech and crypto industries, exceptional communication skills, and the ability to work cross-functionally to drive product marketing initiatives.</p>

<p style="text-align: left;"><strong>Who You Are:</strong></p>
<ul style="text-align: left;">
  <li>1-3 years of experience in product marketing (B2C/B2B/B2B2C) in a tech or high-growth environment.</li>
  <li>Strong project management and stakeholder management skills, with the ability to balance multiple priorities.</li>
  <li>Exceptional analytical skills with the ability to understand and communicate key insights to advance product marketing initiatives.</li>
  <li>Excellent communication skills, with the ability to work cross-functionally across teams and departments to align marketing efforts.</li>
  <li>Deep knowledge and understanding of financial markets, particularly the crypto industry and its relevant products.</li>
</ul>

<p style="text-align: left;"><strong>What We Offer:</strong></p>
<ul style="text-align: left;">
  <li>Competitive salary and benefits package.</li>
  <li>Opportunity to work closely with a passionate Web3 team that is reshaping the advertising landscape.</li>
  <li>Flexible working hours and remote work options.</li>
  <li>A collaborative and innovative work environment that fosters growth and creativity.</li>
</ul>

<p style="text-align: left;">If you have a passion for product marketing and want to work in a dynamic decentralized space, we encourage you to apply and become part of the dOpus journey!</p>

<p style="text-align: left;"><em>dOpus is an equal opportunity employer committed to fostering an inclusive, innovative environment with the best employees. Therefore, we provide employment opportunities without regard to age, race, color, ancestry, national origin, disability, gender, or any other protected status in accordance with applicable law.</em></p>
`,     
      },{       
        location: {
          name: "Remote (Worldwide)"
        },
        departments: [
          {
            id: 1001,
            name: "Social Media & Web3",
            child_ids: [],
            parent_id: null
          }
        ],
        id: 1039683,
        updated_at: "2024-09-17T08:22:34-04:00",
        title: "Social Media Manager",
        content: `
          <p style="text-align: left;">dOpus is revolutionizing decentralized advertising by allowing property owners to monetize their spaces—be it a car, home, or any owned property—by connecting with brands through our marketplace. As a Social Media Manager, you will be responsible for managing dOpus’ social media presence, driving engagement, and helping grow our community. We are looking for a creative and strategic individual who can bring dOpus’ vision to life across various social platforms while driving growth and brand awareness.</p>

<p style="text-align: left;"><strong>Your Responsibilities:</strong></p>
<ul style="text-align: left;">
  <li>Manage the social media calendar and post daily content such as tweets, memes, and educational posts.</li>
  <li>Help refine and execute the social media strategy to drive growth and engagement.</li>
  <li>Leverage marketing, community events, Twitter Spaces, Discord community calls, and other channels to grow dOpus' presence.</li>
  <li>Draft tweets and social posts, gather feedback from the team, and iterate to ensure content resonates with the audience.</li>
  <li>Create and design social media banners, graphics, and logos to complement posts.</li>
</ul>

<p style="text-align: left;"><strong>What We Offer:</strong></p>
<ul style="text-align: left;">
  <li>Competitive salary and benefits package.</li>
  <li>Opportunity to work closely with a passionate Web3 team that is reshaping the advertising landscape.</li>
  <li>Flexible working hours and remote work.</li>
  <li>A collaborative and innovative work environment that fosters growth and creativity.</li>
</ul>

<p style="text-align: left;">If you are passionate about social media, content creation, and growing online communities, we encourage you to apply and become part of the dOpus journey!</p>

<p style="text-align: left;"><em>dOpus is an equal opportunity employer committed to fostering an inclusive, innovative environment with the best employees. Therefore, we provide employment opportunities without regard to age, race, color, ancestry, national origin, disability, gender, or any other protected status in accordance with applicable law.</em></p>
`,     
      },{       
        location: {
          name: "Remote (CET and EET)"
        },
        departments: [
          {
            id: 1008,
            name: "Graphic Design",
            child_ids: [],
            parent_id: null
          }
        ],
        id: 8288933,
        updated_at: "2024-09-24T09:22:34-04:00",
        title: "Graphic Designer",
        salary: "$89k - $164k",
        content: `
          <p style="text-align: left;">dOpus is revolutionizing decentralized advertising by allowing property owners to monetize their spaces—be it a car, home, or any owned property—by connecting with brands through our marketplace. We are seeking a Graphic Designer who will be responsible for crafting visually compelling assets that represent our brand across all digital channels. You will collaborate with multiple teams to ensure that our design efforts effectively communicate our mission and values, while maintaining a consistent brand presence. If you’re a creative professional with a passion for design and experience in digital media, we want you to join our team and contribute to shaping the visual language of decentralized advertising.</p>

<p style="text-align: left;"><strong>Responsibilities:</strong></p>
<ul style="text-align: left;">
  <li>Collaborate with the Brand team, product leaders, and cross-functional teams to create visually engaging designs for websites, social media, community campaigns, events, and more.</li>
  <li>Translate complex, abstract technical concepts into visually compelling assets that align with our brand vision.</li>
  <li>Maintain consistency in visual style across all digital channels, ensuring adherence to established brand guidelines.</li>
  <li>Create and edit visual content for presentations, reports, and internal communications.</li>
  <li>Manage multiple design projects simultaneously, ensuring all deadlines are met while maintaining high-quality standards.</li>
  <li>Stay updated on industry trends and incorporate design best practices to enhance the quality of dOpus' visual assets.</li>
  <li>Provide creative input and feedback during design brainstorming sessions and reviews.</li>
</ul>

<p style="text-align: left;"><strong>Qualifications:</strong></p>
<ul style="text-align: left;">
  <li>Bachelor’s degree in Graphic Design, Visual Arts, or a related field.</li>
  <li>4+ years of experience creating visually impactful designs for digital platforms, including websites and social media.</li>
  <li>Expert-level knowledge of design tools such as Figma, Adobe Creative Suite (Photoshop, Illustrator, InDesign), and other design software.</li>
  <li>Strong understanding of design principles, color theory, and typography.</li>
  <li>Ability to work collaboratively in a fast-paced environment while managing multiple projects.</li>
  <li>Experience designing within Agile workflows and collaborating with cross-functional teams, including engineering and product.</li>
</ul>

<p style="text-align: left;"><strong>Bonus Points:</strong></p>
<ul style="text-align: left;">
  <li>Experience with motion graphics and animation tools.</li>
  <li>Experience working for a Web3 company or in decentralized technology fields.</li>
  <li>Knowledge of UI/UX design for web and mobile applications.</li>
  <li>Contributions to open-source design projects or design communities.</li>
</ul>

<p style="text-align: left;"><strong>What We Offer:</strong></p>
<ul style="text-align: left;">
  <li>Competitive salary with remote options.</li>
  <li>Opportunity to work closely with a passionate Web3 team that is reshaping the advertising landscape.</li>
  <li>Participation in employee bonus and Airdrop programs.</li>
  <li>Excellent career growth opportunities for high performers.</li>
</ul>

<p style="text-align: left;">If you are passionate about design and want to shape the visual identity of a groundbreaking decentralized platform, we encourage you to apply and become part of the dOpus journey!</p>

<p style="text-align: left;"><em>dOpus is an equal opportunity employer committed to fostering an inclusive, innovative environment with the best employees. Therefore, we provide employment opportunities without regard to age, race, color, ancestry, national origin, disability, gender, or any other protected status in accordance with applicable law.</em></p>
`,     
      },
    ]
  };
  
  export default jobsData;
  